
import { reactive, computed } from "vue";
import { utils } from "../utils";
import { useApp } from "./app";
import { useStore } from 'vuex';

import { useConfirm } from 'primevue/useconfirm';
export function useEditPage({ props, formData, v$, afterSubmit }) {

	const app = useApp();
	const store = useStore();
	const confirm = useConfirm();
	const state = reactive({
		id: null,
		loading:false,
		pageReady:false,
		submitted: false,
		saving: false,
		errorMsg: ''
	});

	const apiUrl = computed(() => {
		if(props.id){
			return props.apiPath + '/' + encodeURIComponent(props.id);
		}
		return props.apiPath;
	});

	const currentRecord = computed( {
		get: function () {
			return store.getters[`${props.pageName}/currentRecord`];
		},
		set: function (value) {
			store.commit(`${props.pageName}/setCurrentRecord`, value);
		},
	});

	function normalizeFormData(formValues) {
		const postData = { ...formValues }
		Object.keys(postData).forEach(function (key) {
			const fieldValue = postData[key];
			if (Array.isArray(fieldValue)) {
				postData[key] = fieldValue.toString();
			}
			else if (fieldValue instanceof Date) {
				postData[key] = fieldValue.toISOString().slice(0, 19).replace('T', ' ');
			}
			else if(fieldValue === ''){
				postData[key] = null;
			}
		});
		return postData;
	}
	
	function submitForm(){
		const confirmMsg = props.msgBeforeSave;
		if(confirmMsg){
			confirm.require({
				message: confirmMsg,
				header: props.msgTitle,
				icon: 'pi pi-pencil',
				accept: async () => {
					saveFormData();
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		}
		else{
			saveFormData();
		}
	}

	async function saveFormData(){
		state.submitted = true;
		const isFormValid = !v$.value.$invalid;
		if(!isFormValid){
			app.flashMsg(props.formValidationError, props.formValidationMsg, "error");
			return;
		}
		state.saving = true;
		let url = apiUrl.value;
		let id = props.id;
		const payload = normalizeFormData(formData);
		let data = { id,  url, payload }
		try{
			let response = await store.dispatch(`${props.pageName}/updateRecord`, data);
			if(afterSubmit){
				afterSubmit(response)
			}
		}
		catch(err) {
			app.showPageRequestError(err);
		}
		finally{
			state.saving = false;
			state.submitted = false;
		}
	}

	function mapToFormData(apiData) {
		const formValues = { ...apiData }
		Object.keys(formValues).forEach(function (key) {
			const fieldValue = formValues[key];
			const fieldDefaultValue = formData[key];
			if (Array.isArray(fieldDefaultValue)) {
				if (fieldValue) {
					formValues[key] = fieldValue.toString().split(",");
				}
				else {
					formValues[key] = fieldDefaultValue
				}
			}
			else if (fieldDefaultValue instanceof Date) {
				formValues[key] = new Date(fieldValue);
			}
		});
		return formValues;
	}

	async function load() {
		state.pageReady = false;
		state.loading = true;
		state.item = null;
		let url = apiUrl.value;
		try{
			await store.dispatch(`${props.pageName}/fetchRecord`, url);
			const formValues = mapToFormData({...currentRecord.value});
			Object.assign(formData, formValues); //update form data
			state.pageReady = true;
		}
		catch(err){
			app.showPageRequestError(err);
		}
		finally{
			state.loading = false;
		}
	}

	function getErrorClass(field){
		return {"p-invalid": v$.value[field].$invalid && state.submitted};
	}

	function getFieldError(field){
		const fieldErrors = v$.value[field].$silentErrors;
		if(fieldErrors.length){
			return fieldErrors[0].$message; //return the first error
		}
		return null;
	}

	function isFieldValid(field, index){
		if(index===undefined){
			return v$.value[field].$invalid && state.submitted;	
		}
		else if(v$.value.$each.$response.$errors[index][field].length && state.submitted){
			return true;
		}
		return false;
	}

	const computedProps = {
		apiUrl,
		currentRecord
	}

	const methods = {
		load,
		submitForm,
		getFieldError,
		getErrorClass,
		isFieldValid
	}
	
	return {
		state,
		computedProps,
		methods
	}
}